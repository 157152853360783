/* eslint-disable indent */
import EnvironmentService from "../environment.service";
import { useMutation, useQuery } from 'react-query';
import useAxiosApiClient from "../../components/shared/useAxiosApiClient";
import ProductSearchDto from "../../dto/ProductSearchDto";

export const useSearchOffersMutation = () => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    return useMutation(async (productSearchDto) => {
        const { data } = await axiosApiClient.post(baseApiUrl + '/api/products/GetByFilters',
            productSearchDto,
            { });
        return data;
    });
};

export const useGetSubCategoriesForProductTypeIdQuery = (productTypeId) => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    return useQuery('getSubCategoriesForProductTypeIdQuery', async () => {
        const { data } = await axiosApiClient.get(
            baseApiUrl + `/api/categories/SubCategoriesByProductType/${productTypeId}`,
            {  });
        return data;
    },
        //{ enabled: !!productTypeId }
    );
};

export const useGetFeaturedProductsQuery = (clientId) => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    const state = localStorage.getItem('choices-state');
    return useQuery('getFeaturedProductsQuery', async () => {
        const { data } = await axiosApiClient.get(baseApiUrl + `/api/products/featured/${clientId}/${state}`,
            {  });
        return data;
    },
        { enabled: !!clientId }
    );
};

export const useGetProductDetailsMutation = () => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    return useMutation(async (productInfo) => {
        const { data } = await axiosApiClient.post(
            baseApiUrl + `/api/products/productdetail/${productInfo.productTypeId}/${productInfo.id}`,
            productInfo.productSearchDto,
            {  }
        );
        return data;
    });
};

export const useGetRecentActivityQuery = (userId) => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    return useQuery('getRecentActivityQuery', async () => {
        const { data } = await axiosApiClient.get(baseApiUrl + `/api/orders/recent-activity/${userId}`,
            {  });
        return data;
    },
        //{ enabled: !!userId }
    );
};

export const useSendContactEmailMutation = () => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    return useMutation(async (contactDto) => {
        const { data } = await axiosApiClient.post(baseApiUrl + `/api/communications/contact-us`, contactDto,
            {  });
        return data;
    });
};

export const useNominatePractitionerMutation = () => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    return useMutation(async (nominatePractitionerDto) => {
        const { data } = await axiosApiClient.post(
            baseApiUrl + `/api/communications/nominate-practitioner`,
            nominatePractitionerDto,
            {  }
        );
        return data;
    });
};

export const useGetClientByIdQuery = (clientId) => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    return useQuery('getClientByIdQuery', async () => {
        const { data } = await axiosApiClient.get(baseApiUrl + `/api/client/${clientId}`,
            {  });
        return data;
    },
        { enabled: !!clientId }
    );
};

export const useGetFitbitPromoUriQuery = () => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    return useQuery(
        'getFitbitPromoUriQuery',
        async () => {
            const username = localStorage.getItem('choices-email');
            // eslint-disable-next-line max-len
            const response = await axiosApiClient.get(baseApiUrl + `/api/fitbit/username/${username}/partnername/Fitbit`,
                {  });
            return response.data;
        },
        {
            refetchOnWindowFocus: false,
            enabled: false
        }
    );
};

export const usePublishEventMutation = () => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    return useMutation(async (eventDto) => {
        const response = await axiosApiClient.post(
            baseApiUrl + `/api/orders/create-order`,
            eventDto,
            {  }
        );
        return response.status;
    });
};

export const useSendOfferEmailMutation = () => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    return useMutation(async (offerObject) => {
        return await axiosApiClient.post(
            baseApiUrl + `/api/products/send-offer/${offerObject.productId}`,
            new ProductSearchDto(offerObject.productTypeId),
            {  }
        );
    });
};

export const usePractionerPreview = () => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiURL = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    const route = baseApiURL + '/api/products/PractitionerPreview';
    const env = global.origin;

    return useMutation(async (searchRadius, clientId) => {
        const data = {
            "categoryId": 0,
            "clientId": window.sessionStorage['choices-referring-clientId'] ?? 1,
            "geoLocation": {
                "latitude": window.localStorage['choices-latitude'],
                "longitude": window.localStorage['choices-longitude'],
            },
            "pagination": {
                "page": 1,
                "pageSize": 40
            },
            "productTypeId": 1,
            "searchRadius": searchRadius,
            "sortParam": {
                "sortOrder": 1,
                "sortType": 2
            },
            "subCategoryId": [1, 2, 3, 4, 6, 7, 10, 13, 14, 15, 16, 18, 19, 20],
            "userEmailAddress": "",
            "userState": window.localStorage['choices-state'],
            "whlServerUrl": env ,
        };

        return await axiosApiClient.post(
            route,
            data,
            {  }
        );

    });

};

export const useGetUserSubscriptionDetailsQuery = (userId) => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");

    return useQuery(
        'getUserSubscriptionDetails',
        async () => {
            const { data } = await axiosApiClient.get(baseApiUrl + `/api/orders/subscription-details/${userId}`,
                {  });
            return data;
        },
        //{ enabled: !!userId, }
    );
};

export const useCreateCheckoutSessionMutation = () => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");

    return useMutation(async (customerEmail) => {
        const { data } = await axiosApiClient.post(baseApiUrl + '/api/orders/create-checkout-session',
            { customerEmail },
            {  });
        return data;
    });
};

export const useIsSubscribedMutation = () => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    const username = localStorage.getItem('choices-email');

    return useMutation(async (requestObject) => {
        return await axiosApiClient.post(
            baseApiUrl + `/api/orders/is-subscribed/${username}`,
            requestObject.sessionId,
            {  }
        );
    });
};

export const useCreateRefundMutation = () => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    const username = localStorage.getItem('choices-email');

    return useMutation(async (requestObject) => {
        return await axiosApiClient.post(
            baseApiUrl + `/api/orders/refund-subscription/${username}`,
            requestObject.sessionId,
            {  }
        );
    });
};

export const useGetClientIdWithSlug = () => {
    const axiosApiClient = useAxiosApiClient(true);
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");

    return useMutation(async (urlSlug)=>{
        let config = {
            method: 'get',
            url:  baseApiUrl + `/api/client/domain?url=${urlSlug}`
          };
        return await axiosApiClient.request(config).then((response)=>{
            window.sessionStorage['choices-referring-clientId'] = response.data.client.id;
        }).catch((err)=>{
            console.log(err);
        });
        
    });
    
};

