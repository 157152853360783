import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    HashRouter,
} from "react-router-dom";
import StateProvider from './context/StateProvider';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Auth0Provider } from "@auth0/auth0-react";
import EnvironmentService from './services/environment.service';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import ErrorBoundary from './components/error-boundary/ErrorBoundary';
import ReferUrl from './pages/refer-url/ReferUrl';
import ClientReferringUrl from './pages/refer-url/ClientReferringUrl';
import HomePage from './pages/home/HomePage';
import Register from './pages/register/Register';
import RegistrationRedirect from './pages/register/RegistrationRedirect';
import AccessDenied from './pages/access-denied/AccessDenied';
import ConfirmEmail from './pages/confirm-email/ConfirmEmail';
import Communication from './pages/communication/Communication';
import ResetPassword from './pages/reset-password/ResetPassword';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import PrivacyPolicy from './pages/privacy/PrivacyPolicy';
import TermsOfUse from './pages/terms-of-use/TermsOfUse';
import AccountPage from './pages/account/AccountPage';
import ManagePlanPage from './pages/manage-plan/ManagePlanPage';
import RecentActivityPage from './pages/recent-activity/RecentActivityPage';
import Contact from './pages/contact/Contact';
import NominatePractitioner from './pages/nominate-practitioner/NominatePractitioner';
import ContentFilter from './components/client-filter/ContentFilter';
import SearchPage from './pages/search/SearchPage';
import ProductTypeId from './models/productTypeId';
import { FilterOptions } from './models/filterOptions';
import { SortOptions, SortType } from './models/sortOptions';
import PractitionerDetailsPage from './pages/practitioners/details/PractitionerDetailsPage';
import ProductDetailsPage from './pages/products/details/ProductDetailsPage';
import FitnessCenterDetailsPage from './pages/fitness-centers/details/FitnessCenterDetailsPage';
import ResourceCenterPage from './pages/resource-center/ResourceCenterPage';
import SearchResults from './pages/resource-center/SearchResults';
import SubscriptionPrompt from './pages/subscription-prompt/SubscriptionPrompt';
import SubscriptionSuccess from './pages/subscription-success/SubscriptionSuccess';
import SubscriptionError from './pages/subscription-error/SubscriptionError';
import NotFound from './pages/not-found/NotFound';
import { OAuthRedirect } from './pages/oauth-redirect/OAuthRedirect';
import { OAuthRedirectStripe } from './pages/oauth-redirect/OAuthRedirectStripe';

const queryClient = new QueryClient();

const redirectUri = EnvironmentService.getEnvironmentVariable("REDIRECT_URI");
const clientId = EnvironmentService.getEnvironmentVariable("OAUTH_UI_CLIENT_ID");
const audience = EnvironmentService.getEnvironmentVariable("OAUTH_AUDIENCE");
const domain = EnvironmentService.getEnvironmentVariable("OAUTH_DOMAIN");

const renderRoute =
    EnvironmentService.getEnvironmentString() === "development" ||
    EnvironmentService.getEnvironmentString() === "qa" ||
    EnvironmentService.getEnvironmentString() === "uat" ||
    EnvironmentService.getEnvironmentString() === "production";

const currentEnv = EnvironmentService.getEnvironmentString() !== 'production'
    && EnvironmentService.getEnvironmentString() !== "uat";

const App = () => (
    <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
            audience: audience,
            redirect_uri: redirectUri,
            scope: "openid profile email offline_access",
        }}
        useRefreshTokens={true}
        useRefreshTokensFallback={true}
    >
        <StateProvider>
            <QueryClientProvider client={queryClient}>
                <HashRouter>
                    <Routes>
                        <Route path="register" element={<ReferUrl />} />
                    </Routes>
                </HashRouter>
                <BrowserRouter>
                    <ErrorBoundary>
                        <ScrollToTop>
                            <Routes>
                                {/* UNPROTECTED ROUTES */}
                                <Route path="oauth/authorize" element={<OAuthRedirect />} />
                                <Route path="/:clientUrl" element={<ClientReferringUrl />} />
                                <Route path="login" element={<HomePage />} />
                                <Route path="register" element={<Register />} />
                                <Route path="register-redirect" element={<RegistrationRedirect />} />
                                <Route path="access-denied" element={<AccessDenied />} />
                                <Route path="confirm-email" element={<ConfirmEmail />} />
                                <Route path="communication" element={<Communication />} />
                                <Route path="reset-password" element={<ResetPassword />} />
                                <Route path="forgot-password" element={<ForgotPassword />} />
                                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                                <Route path="terms-of-use" element={<TermsOfUse />} />

                                {/* PROTECTED ROUTES */}
                                <Route path="" element={<HomePage />} />
                                <Route path="account" element={<AccountPage />} />
                                <Route path="manage-plan" element={<ManagePlanPage />} />
                                <Route path="recent-activity" element={<RecentActivityPage />} />
                                <Route path="contact" element={<Contact />} />
                                <Route path="nominate-practitioner" element={<NominatePractitioner />} />
                                <Route
                                    path="practitioners"
                                    element={
                                        <ContentFilter>
                                            <SearchPage
                                                productTypeId={ProductTypeId.PROVIDER}
                                                filterOptions={[
                                                    FilterOptions.SUBCATEGORIES,
                                                    FilterOptions.LOCATION,
                                                    FilterOptions.RADIUS,
                                                ]}
                                                sortOptions={SortOptions}
                                                pageTitle={"Practitioners"}
                                                key={"Practitioners"}
                                            />
                                        </ContentFilter>
                                    }
                                />
                                <Route
                                    path="practitioners/:practitionerId"
                                    element={
                                        <ContentFilter>
                                            <PractitionerDetailsPage />
                                        </ContentFilter>
                                    }
                                />
                                <Route
                                    path="products"
                                    element={
                                        <SearchPage
                                            productTypeId={ProductTypeId.VENDOR}
                                            filterOptions={[FilterOptions.SUBCATEGORIES]}
                                            sortOptions={SortOptions.filter(
                                                (option) => option.value.sortType !== SortType.Distance
                                            )}
                                            pageTitle={"Products & Services"}
                                            key={"Products & Services"}
                                        />
                                    }
                                />
                                <Route path="products/:productId" element={<ProductDetailsPage />} />
                                <Route
                                    path="fitness-centers"
                                    element={
                                        <ContentFilter>
                                            <SearchPage
                                                pageTitle={"Fitness & Wellness Centers"}
                                                key={"Fitness & Wellness Centers"}
                                                productTypeId={ProductTypeId.FACILITY}
                                                filterOptions={[
                                                    FilterOptions.LOCATION,
                                                    FilterOptions.RADIUS,
                                                ]}
                                                sortOptions={SortOptions}
                                            />
                                        </ContentFilter>
                                    }
                                />
                                <Route
                                    path="fitness-centers/:fitnessCenterId"
                                    element={
                                        <ContentFilter>
                                            <FitnessCenterDetailsPage />
                                        </ContentFilter>
                                    }
                                />

                                {renderRoute && currentEnv && (
                                    <Route
                                        path="resource-center"
                                        element={
                                            <ContentFilter>
                                                <ResourceCenterPage />
                                            </ContentFilter>
                                        }
                                    />
                                )}

                                {renderRoute && currentEnv && (
                                    <Route
                                        path="resource-center/:searchInput"
                                        element={
                                            <ContentFilter>
                                                <SearchResults />
                                            </ContentFilter>
                                        }
                                    />
                                )}
                                <Route
                                    path="subscribe/"
                                    element={
                                        <ContentFilter>
                                            <SubscriptionPrompt />
                                        </ContentFilter>
                                    }
                                />
                                <Route
                                    path="subscribe-success/"
                                    element={                                       
                                        <ContentFilter>
                                            <OAuthRedirectStripe>
                                                <SubscriptionSuccess />
                                            </OAuthRedirectStripe>
                                        </ContentFilter>                                       
                                    }
                                />
                                <Route
                                    path="subscribe-error/"
                                    element={
                                        <ContentFilter>
                                            <SubscriptionError />
                                        </ContentFilter>
                                    }
                                />

                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </ScrollToTop>
                    </ErrorBoundary>
                </BrowserRouter>
            </QueryClientProvider>
        </StateProvider>
    </Auth0Provider>
);

export default App;
