import React, { useMemo, useState } from 'react';
import styles from './ZeroResults.module.scss';
import noProviders from '../../assets/images/no_providers.svg';
import noProducts from '../../assets/images/no_products.svg';
import noFacilities from '../../assets/images/no_facilities.svg';
import ProductTypeId from '../../models/productTypeId';
import { Link } from 'react-router-dom';

const ZeroResults = ({productTypeId}) => {
    const [images, setImages] = useState({});

    useMemo(()=> {
        let imageMap = {};
        imageMap[ProductTypeId.VENDOR] = noProducts;
        imageMap[ProductTypeId.PROVIDER] = noProviders;
        imageMap[ProductTypeId.FACILITY] = noFacilities;
        setImages(imageMap);
    },[]);

    return (
        <div className={styles.container}>
            <p className={styles.heading}>
                Choices by WholeHealth Living is having trouble locating practitioners in your area.
                Please enable location services on your browser or enter your location
                (zip code or city/state) in the top left field and hit enter.
                If you are still having issues, please contact our
                Experience Center at 800-274-7526 or visit the Choices by WholeHealth Living
                <Link to="/products">products and services</Link> to discover more cost savings.
            </p>
            <div className={styles.imgContainer}>
                <img
                    src={images[productTypeId]}
                    alt=" Choices by WholeHealth Living is having trouble locating practitioners in your area.
                Please enable location services on your browser or enter your location
                (zip code or city/state) in the top left field and hit enter."
                />
            </div>
        </div>
    );
};

export default ZeroResults;
