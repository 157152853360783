import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const useAxiosApiClient = (isAuthRequired) => {
    const apiClient = axios.create({
        headers: {
            "Content-Type": "application/json",
        },
    });

    if(!isAuthRequired){
        return apiClient;
    }

    const {getAccessTokenSilently} = useAuth0();
    apiClient.interceptors.request.use(async (config) => {
        try{
            const token = await getAccessTokenSilently();
            config.headers.Authorization = `bearer ${token}`;
        } catch (error) {
            console.error("Error interceptor and getting token", error);
        }
        return config;
    });

    return apiClient;
};

export default useAxiosApiClient;