import { useState, useEffect, useContext, createContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useChoicesContext } from '../context/ChoicesContext';
import EnvironmentService from '../services/environment.service';

export const TokenContext = createContext(null);

export const useAuthLogic = () => {
    const { isAuthenticated, getIdTokenClaims, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const {
        setReferringClientData,
        setJsonWebToken,
        setEmail,
        setState,
        setFirstName,
        setLastName,
        setClientDomainData,
        setUserId,
        setSessionId
    } = useChoicesContext();

    const [token, setToken] = useState(null);

    const useAuthLogic = async () => {
        try {
            if (isAuthenticated) {
                const idClaims = await getIdTokenClaims();

                const email = idClaims.email;
                const firstName = idClaims.given_name;
                const lastName = idClaims.family_name;
                const userId = idClaims.sub;

                const state = idClaims['state'];
                const domainId = idClaims['domain_id'];
                const domainUrl = idClaims['domain_url'];
                const clientId = idClaims['https://whlchoices.com/client_id'];
                const expireTime = idClaims.exp * 1000;
                const currentTime = Date.now();
                const timeout = expireTime - currentTime - 60000;

                localStorage.setItem('choices-clientId', clientId);
                localStorage.setItem('choices-email', email);
                localStorage.setItem('choices-firstName', firstName);
                localStorage.setItem('choices-lastName', lastName);

                setEmail(email);
                setFirstName(firstName);
                setLastName(lastName);
                setState(state);
                setClientDomainData(clientId, domainId);
                setUserId(userId);
                setSessionId(idClaims.sid);

                setReferringClientData(clientId, domainId, domainUrl);
                setTimeout(useAuthLogic, timeout);
            } else {
                // Do nothing for now.
            }
        } catch (error) {
            console.error("Error renewing token", error);
        }
    };

    useEffect(() => {
        useAuthLogic();
    }, [isAuthenticated]);

    return { token, isAuthenticated };
};
